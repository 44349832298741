import React, { useEffect, useState } from 'react'
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import {
  OnboardingPageProps,
  NextButton,
  ExtendedClientOnboardingType,
  RightPaneComponentType,
} from './onboardingManager'
import { CustomRadioGroup, CustomRadioInput } from 'components/CustomRadio'
import { CLIENT_MAX_FILE_SIZE } from 'app/constants'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { updateEntities } from 'redux-query'
import { validatePhone } from 'utilities'
import { PostalCodeField } from 'components/PostalCodeField'
import { StateProvinceSelect } from 'components/ProvinceSelect'
import { Loading } from 'components/Loading'
import greyArrow from 'assets/images/Grey-Arrow.svg'
import { Errors } from 'hooks/useFormServerErrors'
import { FormServerErrors } from 'components/FormServerErrors'
import { Client, uploadClientFile, Province } from 'api'
import { useMutation } from 'redux-query-react'
import { getFileErrorMessages } from 'components/FileUploader'
import { MinimalFileUploader } from 'components/MinimalFileUploader'
import { CountryRadio } from 'components/CountryRadio'
import { ExternalLink } from 'components/ExternalLink'
import {
  getBusinessTypes,
  getClientAddressCategories,
  getClientAddressTypes,
  getBusinessCategories,
  getProvinces,
  getStates,
} from 'app/selectors'

const registryIdProvincesCopy = {
  AB: { fieldName: 'Business Registry ID', inputLength: '8 - 10 digits.' },
  BC: { fieldName: 'Business Registry ID', inputLength: '7-9 digits.' },
  SK: { fieldName: 'Business Registry ID', inputLength: '9 digits.' },
  MB: { fieldName: 'Business Registry ID', inputLength: '7-8 digits.' },
  ON: { fieldName: 'Business Registry ID', inputLength: '7-8, or 10 digits.' },
  QC: { fieldName: 'Quebec File Number', inputLength: '10 digits.' },
  NS: { fieldName: 'Business Registry ID', inputLength: '7 digits.' },
  NB: { fieldName: 'Business Registry ID', inputLength: '6 digits.' },
  PE: { fieldName: 'Business Registry ID', inputLength: null },
  NL: { fieldName: 'Business Registry ID', inputLength: '5 digits.' },
  YT: { fieldName: 'Business Registry ID', inputLength: null },
  NT: { fieldName: 'Business Registry ID', inputLength: null },
  NU: { fieldName: 'Business Registry ID', inputLength: null },
}

const registryIdStatesCopy = {
  AK: { fieldName: 'Alaska Business Entity Number', inputLength: '6 or 8 digits long. ' },
  AL: { fieldName: 'Alabama Entity ID', inputLength: '6 digits long. ' },
  AR: { fieldName: 'Arkansas Filing Number', inputLength: '5 or 9 digits long. ' },
  AZ: { fieldName: 'Arizona Entity ID', inputLength: '7-9 digits long. ' },
  CA: { fieldName: 'Corporate Entity Number', inputLength: '8 or 10 digits long. ' },
  CO: { fieldName: 'Colorado Business ID Number', inputLength: '11 digits long. ' },
  CT: { fieldName: 'Connecticut Business ID Number', inputLength: '7 digits long. ' },
  DC: { fieldName: 'Business Registry ID Number', inputLength: '14 digits long. ' },
  DE: { fieldName: 'Delaware State File Number', inputLength: '7 digits long. ' },
  FL: { fieldName: 'Florida Document Number', inputLength: '6 or 12 digits long. ' },
  GA: { fieldName: 'Control Number', inputLength: '7-8 digits long. ' },
  HI: { fieldName: 'Hawaii File Number', inputLength: '6-11 digits long. ' },
  IA: { fieldName: 'Iowa Business Number', inputLength: '6 digits long. ' },
  ID: { fieldName: 'Idaho File Number', inputLength: '4-7 or 10 digits long. ' },
  IL: { fieldName: 'Illinois File Number', inputLength: '8 digits long. ' },
  IN: { fieldName: 'Indiana Business ID Number', inputLength: '13 digits long. ' },
  KS: { fieldName: 'Kansas Entity ID Number', inputLength: '7 digits long. ' },
  KY: { fieldName: 'Kentucky Organization Number', inputLength: '7 digits long. ' },
  LA: { fieldName: 'Louisiana Charter Number', inputLength: '9 digits long. ' },
  MA: { fieldName: 'Massachusetts ID Number', inputLength: '9 digits long. ' },
  MD: { fieldName: 'Maryland Department ID', inputLength: '9 digits long. ' },
  ME: { fieldName: 'Maine Charter Number', inputLength: '10 digits long. ' },
  MI: { fieldName: 'Michigan ID Number', inputLength: '6 or 9 digits long. ' },
  MN: { fieldName: 'Minnesota File Number ', inputLength: '5-6, 9 or 13 digits long. ' },
  MS: { fieldName: 'Mississippi Business ID', inputLength: '6-7 digits long. ' },
  MO: { fieldName: 'Missouri Charter Number', inputLength: '8-9 or 11 digits long. ' },
  NE: { fieldName: 'SOS Account Number', inputLength: '7-8 or 10 digits long.' },
  NV: { fieldName: 'Nevada Entity Number', inputLength: '10-11 or 13 digits long. ' },
  NH: { fieldName: 'New Hampshire Business ID', inputLength: '5-6 digits long. ' },
  NJ: { fieldName: 'New Jersey Entity ID', inputLength: '10 digits long. ' },
  NM: { fieldName: 'New Mexico Entity Business ID Number', inputLength: '7 digits long. ' },
  NY: { fieldName: 'New York DOS ID Number', inputLength: '7 digits long. ' },
  NC: { fieldName: 'North Carolina SOS ID', inputLength: '7 digits long. ' },
  ND: { fieldName: 'North Dakota System ID', inputLength: '10 digits long. ' },
  OH: { fieldName: 'Ohio Entity Number', inputLength: '6-8 digits long. ' },
  OK: { fieldName: 'Oklahoma Filing Number', inputLength: '10 digits long. ' },
  OR: { fieldName: 'Oregon Registry Number', inputLength: '8-9 digits long. ' },
  PA: { fieldName: 'Pennsylvania Entity Number', inputLength: '5-7 digits long. ' },
  SC: { fieldName: 'Business Registry ID Number', inputLength: '6 digits long.' },
  SD: { fieldName: 'South Dakota Business ID', inputLength: '8 digits long. ' },
  TN: { fieldName: 'Tennessee Control Number', inputLength: '9 digits long. ' },
  TX: { fieldName: 'Texas Taxpayer Number', inputLength: '10-11 digits long.' },
  UT: { fieldName: 'Utah Entity Number', inputLength: '12-13 digits long. ' },
  VA: { fieldName: 'Virginia SCC/Entity ID', inputLength: '8 digits long. ' },
  WA: { fieldName: 'Washington UBI Number', inputLength: '9 digits long. ' },
  WV: { fieldName: 'Organization Number', inputLength: '4-6 digits long. ' },
  WI: { fieldName: 'Wisconsin Entity ID', inputLength: '7 digits long. ' },
  WY: { fieldName: 'Wyoming Filing ID', inputLength: '14 digits long. ' },
}

export const BusinessCountryComponent = ({
  onNext,
  clientOnboarding,
  validate,
  handleFieldChange,
  formRef,
}: OnboardingPageProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [country, setCountry] = useState('CA')
  const changeAgreements = (e) => {
    setCountry(e.target.value)
    handleFieldChange(e)
  }

  const handleNext = (event, values) => {
    onNext({
      e: event,
      clientValues: {
        ...values,
        clientType: clientOnboarding.stashedClientDetails?.clientType,
      },
    })?.then((response) => {
      if (response?.body?.status === 200) {
        dispatch(
          updateEntities({
            clientOnboarding: (prev: ExtendedClientOnboardingType) => ({
              ...prev,
              stashedClientDetails: { ...prev?.stashedClientDetails, ...values },
            }),
          }),
        )
      }
    })
  }

  return (
    <AvForm model={clientOnboarding.stashedClientDetails} onValidSubmit={handleNext} ref={formRef}>
      <h3>The country you do business in</h3>
      <CountryRadio
        name="countryCode"
        label="Choose One"
        onChange={(e) => changeAgreements(e)}
        validate={{
          required: {
            value: true,
            errorMessage: t('required_field'),
          },
          async: validate,
        }}
      />
      <AvField
        type="checkbox"
        name="userAgreement"
        label={
          <span>
            By creating and using your Rotessa account, you agree to the{' '}
            {country === 'CA' && (
              <ExternalLink to="https://rotessa.com/client-service-agreement-canada/">
                Rotessa user agreement
              </ExternalLink>
            )}
            {country === 'US' && (
              <ExternalLink to="https://rotessa.com/client-service-agreement-united-states">
                Rotessa user agreement
              </ExternalLink>
            )}
            ,{' '}
            {country === 'CA' && (
              <ExternalLink to="https://rotessa.com/legal/customer-terms-and-conditions-for-pre-authorized-debit-payments/">
                pre-authorized debit agreement
              </ExternalLink>
            )}
            {country === 'US' && (
              <ExternalLink to="https://rotessa.com/legal/customer-terms-and-conditions-for-ach-united-states/">
                ACH agreement
              </ExternalLink>
            )}
            , and <ExternalLink to="https://rotessa.com/legal/privacy/">privacy policy</ExternalLink>.
          </span>
        }
        validate={{
          required: {
            value: true,
            errorMessage: t('required_field'),
          },
          async: validate,
        }}
        onChange={handleFieldChange}
      />
      <NextButton />
    </AvForm>
  )
}

export const BusinessTypeComponent = ({
  onNext,
  clientOnboarding,
  validate,
  handleFieldChange,
  formRef,
}: OnboardingPageProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const businessTypes = useSelector(getBusinessTypes)

  const handleNext = (event, values) => {
    onNext({
      e: event,
      clientValues: { ...values, clientType: clientOnboarding.stashedClientDetails?.clientType },
    })?.then((response) => {
      if (response?.body?.status === 200) {
        dispatch(
          updateEntities({
            clientOnboarding: (prev: ExtendedClientOnboardingType) => ({
              ...prev,
              stashedClientDetails: { ...prev?.stashedClientDetails, ...values },
            }),
          }),
        )
      }
    })
  }

  return (
    <AvForm model={clientOnboarding.stashedClientDetails} onValidSubmit={handleNext} ref={formRef}>
      <h3>Business Type</h3>
      <CustomRadioGroup
        name="businessType"
        label="Choose One"
        className="mb-4"
        handleFieldChange={handleFieldChange}
        validate={{
          required: {
            value: true,
            errorMessage: t('required_field'),
          },
          async: validate,
        }}
      >
        {businessTypes &&
          businessTypes.map((type, i) => <CustomRadioInput key={i} name="businessType" label={type} value={type} />)}
      </CustomRadioGroup>
      <NextButton />
    </AvForm>
  )
}

export const BusinessInfoComponent = ({
  onNext,
  validate,
  handleFieldChange,
  serverErrors,
  clientOnboarding,
  formRef,
}: OnboardingPageProps) => {
  const { t } = useTranslation()
  const [showCustomCategory, setShowCustomCategory] = useState(false)
  const [subcategories, setSubcategories] = useState<string[]>()
  const [businessCategory, setBusinessCategory] = useState<string>()
  const [businessCategoryTitle, setBusinessCategoryTitle] = useState<string>()
  const dispatch = useDispatch()
  const businessCategories = useSelector(getBusinessCategories)

  const handleNext = (event, values) => {
    onNext({
      e: event,
      clientValues: {
        ...clientOnboarding.stashedClientDetails,
        ...values,
        businessCategory: businessCategory,
        clientType: clientOnboarding.stashedClientDetails?.clientType,
      },
    })
  }

  const changeBusinessTitle = (value) => {
    let category = businessCategories.find((category) => category.name === value)
    setSubcategories(category?.subcategories)
    setShowCustomCategory(category?.customSubcategory || false)
    setBusinessCategoryTitle(category?.name)
    if (!(category?.subcategories || category?.customSubcategory)) {
      setBusinessCategory(category?.name)
    } else {
      setBusinessCategory(undefined)
    }
  }

  const changeBusinessSubcategory = (value) => {
    setBusinessCategory(businessCategoryTitle + ': ' + value)
  }

  return (
    <>
      <FormServerErrors errors={(serverErrors && serverErrors['business_category']) || []} />
      <AvForm model={clientOnboarding.stashedClientDetails} onValidSubmit={handleNext} ref={formRef}>
        <h3>Business information</h3>
        {clientOnboarding.stashedClientDetails?.businessType === 'Proprietorship' && (
          <p className="subtext">
            If you are doing business under your own name, and are not registered as a business, please provide your
            full legal name for both the Legal Business Name and Operating As Name fields.{' '}
          </p>
        )}
        <AvField
          name="businessName"
          type="text"
          label="Legal Business Name"
          placeholder="The legal business name"
          validate={{
            required: {
              value: true,
              errorMessage: t('required_field'),
            },
            async: validate,
          }}
          onChange={handleFieldChange}
        />
        <AvField
          name="operatingAs"
          type="text"
          label="Operating As Name"
          placeholder="How clients know the business"
          validate={{
            required: {
              value: true,
              errorMessage: t('required_field'),
            },
            async: validate,
          }}
          onChange={handleFieldChange}
        />
        <AvField
          name="position"
          type="text"
          label="Your Role in Relation to the Business"
          placeholder="Ex: Owner"
          validate={{
            required: {
              value: true,
              errorMessage: t('required_field'),
            },
            async: validate,
          }}
          onChange={handleFieldChange}
        />
        <AvField
          name="businessTitle"
          label="Business Category"
          type="select"
          validate={{
            required: {
              value: true,
              errorMessage: t('required_field'),
            },
            async: validate,
          }}
          onChange={(e, v) => {
            changeBusinessTitle(v)
            handleFieldChange(e, v)
          }}
        >
          <option value="" disabled selected hidden>
            Choose one...
          </option>
          {businessCategories &&
            businessCategories.map((category, i) => (
              <option key={i} value={category.name}>
                {category.name}
              </option>
            ))}
        </AvField>
        {subcategories && (
          <AvField
            name="businessSubcategory"
            label="Specify Category"
            type="select"
            validate={{
              required: {
                value: true,
                errorMessage: t('required_field'),
              },
              async: validate,
            }}
            onChange={(e, v) => {
              changeBusinessSubcategory(v)
              handleFieldChange(e, v)
            }}
          >
            <option value="" disabled selected hidden>
              Choose one...
            </option>
            {subcategories.map((category, i) => (
              <option key={i} value={category}>
                {category}
              </option>
            ))}
          </AvField>
        )}
        {showCustomCategory && (
          <AvField
            name="businessSubcategory"
            label="Specify Category"
            type="text"
            placeholder="The business' category"
            validate={{
              required: {
                value: true,
                errorMessage: t('required_field'),
              },
              async: validate,
            }}
            onChange={(e, v) => {
              changeBusinessSubcategory(v)
              handleFieldChange(e, v)
            }}
          />
        )}
        <NextButton />
      </AvForm>
    </>
  )
}

export const BusinessPhoneComponent = ({
  onNext,
  validate,
  handleFieldChange,
  activeClient,
  formRef,
}: OnboardingPageProps) => {
  const { t } = useTranslation()
  const [displayWebsite, setDisplayWebsite] = useState(true)
  return (
    <AvForm
      model={activeClient}
      onValidSubmit={(event, values) => onNext({ e: event, clientValues: values })}
      ref={formRef}
    >
      <h3>Additional information</h3>
      <AvField
        name="phone"
        label="Business Phone Number"
        type="text"
        placeholder="Ex: 1234567890"
        validate={{
          required: {
            value: true,
            errorMessage: t('required_field'),
          },
          tel: validatePhone,
          async: validate,
        }}
        onChange={handleFieldChange}
        groupAttrs={displayWebsite ? undefined : { className: 'mb-0' }}
      />
      {displayWebsite ? (
        <>
          <AvField
            name="websiteUrl"
            label="Business Website"
            type="text"
            placeholder="Ex: rotessa.com"
            validate={{
              required: {
                value: true,
                errorMessage: t('required_field'),
              },
              async: validate,
            }}
            onChange={handleFieldChange}
            groupAttrs={{ className: 'mb-0' }}
          />
          <div className="d-flex justify-content-end w-100">
            <Button color="link" onClick={() => setDisplayWebsite(false)} className="p-0">
              <u>I don't have a website</u>
            </Button>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-end w-100 ">
          <Button color="link" onClick={() => setDisplayWebsite(true)} className="p-0">
            <u>I have a website</u>
          </Button>
        </div>
      )}
      <NextButton />
    </AvForm>
  )
}

export interface AddressFormProps {
  countryCode: string
  addressType?: string
  validSubmitHandler: Function
  showBackButton?: boolean
  backHandler?: Function
  validate: Function
  handleFieldChange: Function
  serverErrors?: Errors
  header?: string | JSX.Element
  subHeader?: string | JSX.Element
  formRef?: React.MutableRefObject<AvForm>
}

export const AddressForm = ({
  countryCode,
  addressType,
  validSubmitHandler,
  showBackButton,
  backHandler,
  validate,
  serverErrors,
  handleFieldChange,
  header,
  subHeader,
  formRef,
}: AddressFormProps) => {
  const clientAddressCategories = useSelector(getClientAddressCategories)
  const { t } = useTranslation()

  return (
    <AvForm
      key={addressType}
      name="address"
      onValidSubmit={(event, values) => validSubmitHandler(event, values, addressType)}
      ref={formRef}
    >
      {showBackButton && backHandler && (
        <Button
          className="text-left d-flex align-items-center btn-onboarding-cancel"
          onClick={(event) => backHandler(event)}
        >
          <img src={greyArrow} />
          CANCEL
        </Button>
      )}
      {header && typeof header == 'string' ? <h3>{header}</h3> : header || 'Address'}
      {subHeader && typeof header == 'string' ? <Label>{subHeader}</Label> : subHeader || ''}

      <AvField
        name="addressAttributes.addressCategory"
        label="Type of address"
        type="select"
        validate={{
          required: {
            value: true,
            errorMessage: t('required_field'),
          },
          async: validate,
        }}
        onChange={handleFieldChange}
      >
        <option value="" disabled selected hidden>
          Choose one...
        </option>

        {clientAddressCategories &&
          Object.values<string>(clientAddressCategories).map((val, index) => <option value={val}>{`${val}`}</option>)}
      </AvField>

      <AvField
        name="addressAttributes.address1"
        label="Street Address"
        type="text"
        placeholder="Ex: 123 First Street"
        validate={{
          required: {
            value: true,
            errorMessage: t('required_field'),
          },
          async: validate,
        }}
        onChange={handleFieldChange}
      />
      <AvField
        name="addressAttributes.city"
        label="Town or City"
        placeholder="Enter the town or city"
        type="text"
        validate={{
          required: {
            value: true,
            errorMessage: t('required_field'),
          },
          async: validate,
        }}
        onChange={handleFieldChange}
      />
      <StateProvinceSelect
        name="addressAttributes.provinceCode"
        countryCode={countryCode}
        required
        serverErrors={serverErrors}
        placeholder="Choose one..."
      />
      <PostalCodeField
        name="addressAttributes.postalCode"
        countryCode={countryCode}
        required
        serverErrors={serverErrors}
        examplePlaceholder={true}
      />
      <NextButton />
    </AvForm>
  )
}

export const AdditionalAddressesPanel = <></>

export const AddressSummaryPanel = (
  <>
    <h3>Why we ask</h3>
    <p>
      If your business has any other addresses such as additional operating locations, it’s important to provide this
      information as it is a helpful part of our verification process.
    </p>
  </>
)

export const OperatingAddressQuestionPanel = (
  <>
    <h3>Why we ask</h3>
    <p>
      When verifying your account we use tools to confirm both the registered address and physical operating address of
      your business. It’s important to accurately provide this information for a smooth onboarding experience.
    </p>
  </>
)

export const OperatingAddressPanel = (
  <>
    <h3>Why we ask</h3>
    <p>
      As part of our KYC (know your customer) process our team uses tools to verify the address you provide. Typically
      this is the physical address where you do business.
    </p>
  </>
)

export const BusinessAddressComponent = ({
  onNext,
  serverErrors,
  validate,
  handleFieldChange,
  activeClient,
  setCurrentPage,
  formRef,
}: OnboardingPageProps) => {
  const [operatingAddressIsRegisteredAddress, setOperatingAddressIsRegisteredAddress] = useState(undefined)
  const [addressType, setAddressType] = useState<string>('')
  const [doCreateAdditionalAddress, setDoCreateAdditionalAddress] = useState(false)
  const clientAddressTypes = useSelector(getClientAddressTypes) || {}

  const validSubmitHandler = (event, values, addressType) =>
    onNext({
      e: event,
      clientValues: { ...values, addressAttributes: { addressType: addressType, ...values['addressAttributes'] } },
    })?.then(() => setDoCreateAdditionalAddress(false))

  const handleOperatingBusinessQuestion = (event, values) => {
    if (values['operatingAddressQuestion'] == 'true') {
      onNext({
        e: event,
        clientValues: {
          addressAttributes: {
            ...activeClient?.addressAttributes,
            addressType: clientAddressTypes['operating'],
          },
        },
      })?.then(() => setOperatingAddressIsRegisteredAddress(values['operatingAddressQuestion']))
    } else setOperatingAddressIsRegisteredAddress(values['operatingAddressQuestion'])
  }
  useEffect(() => {
    let addressTypes = activeClient?.addressesAttributes?.map((address) => address.addressType)

    if (!addressTypes?.includes(clientAddressTypes['registered'])) {
      setAddressType(clientAddressTypes['registered'])
    } else if (!addressTypes?.includes(clientAddressTypes['operating'])) {
      setAddressType(clientAddressTypes['operating'])
      if (operatingAddressIsRegisteredAddress == undefined)
        setCurrentPage((prev) => ({ ...prev!, rightPane: OperatingAddressQuestionPanel }))
      else setCurrentPage((prev) => ({ ...prev!, rightPane: OperatingAddressPanel }))
    } else {
      setAddressType('')
      if (doCreateAdditionalAddress) setCurrentPage((prev) => ({ ...prev!, rightPane: AdditionalAddressesPanel }))
      else setCurrentPage((prev) => ({ ...prev!, rightPane: AddressSummaryPanel }))
    }
  }, [activeClient, operatingAddressIsRegisteredAddress, doCreateAdditionalAddress])

  switch (addressType) {
    case clientAddressTypes['registered']:
      return (
        <AddressForm
          header={'Registered business address'}
          countryCode={activeClient.countryCode || ''}
          addressType={addressType}
          validSubmitHandler={validSubmitHandler}
          serverErrors={serverErrors}
          handleFieldChange={handleFieldChange}
          validate={validate}
          formRef={formRef}
        />
      )
    case clientAddressTypes['operating']:
      if (operatingAddressIsRegisteredAddress == undefined) {
        return (
          <AvForm
            name="operatingAddress"
            onValidSubmit={(event, values) => handleOperatingBusinessQuestion(event, values)}
            ref={formRef}
          >
            <h3>Operating business address</h3>
            <CustomRadioGroup
              name="operatingAddressQuestion"
              label="Is your operating business address the same as your registered business address?"
              required
            >
              <CustomRadioInput label="Yes, they are the same" value="true" name={'ownership_true'} />
              <CustomRadioInput label="No, they are different" value="false" name={'ownership_false'} />
            </CustomRadioGroup>
            <NextButton />
          </AvForm>
        )
      } else {
        return (
          <AddressForm
            header={'Operating business address'}
            subHeader={'The physical operating address of your business'}
            countryCode={activeClient.countryCode || ''}
            addressType={addressType}
            validSubmitHandler={validSubmitHandler}
            serverErrors={serverErrors}
            handleFieldChange={handleFieldChange}
            validate={validate}
            formRef={formRef}
          />
        )
      }
    default: {
      if (doCreateAdditionalAddress) {
        return (
          <AddressForm
            header={'Additional operating address'}
            countryCode={activeClient.countryCode || ''}
            addressType={clientAddressTypes['operating']}
            validSubmitHandler={validSubmitHandler}
            showBackButton
            backHandler={() => setDoCreateAdditionalAddress(false)}
            serverErrors={serverErrors}
            handleFieldChange={handleFieldChange}
            validate={validate}
            formRef={formRef}
          />
        )
      } else {
        return (
          <>
            {!activeClient && <Loading />}
            <AvForm
              name="addressSummary"
              onValidSubmit={(event, values) =>
                onNext({
                  e: event,
                })?.then(() => setDoCreateAdditionalAddress(false))
              }
              ref={formRef}
            >
              <h3>Business addresses</h3>
              {activeClient?.addressesAttributes?.map((address, index) => (
                <AvField
                  name={address.addressType || '' + index}
                  label={address?.addressType?.split(/(?=[A-Z])/)[0] + ' address'}
                  type="text"
                  disabled
                  value={
                    address?.address1 + ' ' + address?.city + ', ' + address.provinceCode + ' ' + address.postalCode
                  }
                />
              ))}
              <Row className="mt-3">
                <Col>
                  <Label>Do you have any other operating addresses?</Label>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="text-center">
                  <Button
                    name="doAdditionalAddress"
                    className="btn btn-onboarding-add-item"
                    onClick={() => setDoCreateAdditionalAddress(true)}
                  >
                    <span>
                      <i className="mdi mdi-plus mr-1 " />
                      Add operating address
                    </span>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col className="text-right">
                  <Label>
                    <i>Optional</i>
                  </Label>
                </Col>
              </Row>
              <NextButton />
            </AvForm>
          </>
        )
      }
    }
  }
}

export const CertificateOfGoodStandingComponent = ({ onNext, activeClient, formRef }: OnboardingPageProps) => {
  const [uploadErrors, setUploadErrors] = useState({} as Errors)
  const [selectedCOGSFile, setSelectedCOGSFile] = useState<File>()
  const states = useSelector(getStates)
  const stateName = states.find((item) => item.code === activeClient?.addressAttributes?.provinceCode)?.name

  const [fileMutationState, doFileMutation] = useMutation((id: number, file: File, title: string) =>
    uploadClientFile({
      id: id,
      file: file,
      title: title,
    }),
  )

  const handleChangeFile = (file: File, accepted: boolean, setAcceptedFile: Function, title: string) => {
    if (accepted) {
      setAcceptedFile(file)
    } else {
      setUploadErrors((prev) => ({ ...prev, [title]: [getFileErrorMessages(file, CLIENT_MAX_FILE_SIZE)] || [] }))
    }
  }

  const handleSubmit = (event, values: Client) => {
    if (activeClient?.id) {
      if (!selectedCOGSFile && !activeClient.certificateOfGoodStanding) {
        setUploadErrors((prev) => ({
          ...prev,
          ['Certificate of Good Standing']: ['Certificate of Good Standing is required.'],
        }))
      } else if (selectedCOGSFile) {
        doFileMutation(activeClient.id, selectedCOGSFile, 'Certificate of Good Standing')?.then((response) => {
          if (response.body?.status === 200) {
            onNext({ e: event, clientValues: values })
          } else {
            setUploadErrors((prev) => ({
              ...prev,
              ['Certificate of Good Standing']: response.body?.messages?.file || response.body?.errors || [],
            }))
          }
        })
      } else {
        onNext({ e: event, clientValues: values })
      }
    }
  }

  const reallyPending = fileMutationState.isPending

  return (
    <AvForm model={activeClient} onValidSubmit={(e, v) => handleSubmit(e, v)} ref={formRef}>
      <h3>Certificate of Good Standing</h3>
      <Row className="mb-3">
        <Col>
          <div className="subtext">
            Please upload a copy of your Certificate of Good Standing from the {stateName} Secretary of State.
          </div>
        </Col>
      </Row>
      <div>
        <MinimalFileUploader
          onFileUpload={(file, accepted) => {
            handleChangeFile(file, accepted, setSelectedCOGSFile, 'Certificate of Good Standing')
          }}
          accept=".jpeg, .jpg, .pdf, .png"
          showPreview={true}
          maxFiles={1}
          disabled={reallyPending}
          maxSize={CLIENT_MAX_FILE_SIZE}
          uploadText="Upload a Certificate of Good Standing"
        />
        <Row>
          {uploadErrors['Certificate of Good Standing'] && (
            <Col className="invalid-feedback d-block">{uploadErrors['Certificate of Good Standing'][0]}</Col>
          )}
        </Row>
      </div>

      <NextButton />
    </AvForm>
  )
}

const BusinessRegistryIdRightPanelComponent = ({ activeClient, onNext }: RightPaneComponentType) => {
  const provinceCode = activeClient?.addressAttributes?.provinceCode || ''
  const provinces: Province[] = useSelector(getProvinces)

  const notRegisteredBusinessContent =
    activeClient?.businessType === 'Proprietorship' ? (
      <Button color="link" onClick={(e) => onNext({ e: e })} className="p-0">
        <u>I’m not a registered business</u>
      </Button>
    ) : null
  let content
  if (activeClient?.countryCode === 'US') {
    const inputLength = registryIdStatesCopy[provinceCode]?.inputLength
    content = (
      <>
        <h3>What is this</h3>
        <p>
          Business ID issued by the Secretary of State.
          {inputLength && ` Typically ${inputLength}`}
        </p>
        {notRegisteredBusinessContent}
      </>
    )
  } else {
    const { fieldName, inputLength } = registryIdProvincesCopy[provinceCode] || {
      fieldName: 'Business Registry ID',
      inputLength: null,
    }
    const provinceName = provinces.find((item) => item.code === provinceCode)?.name
    let description = ''
    if (inputLength) {
      description =
        provinceCode === 'QC'
          ? ` The ${fieldName} is typically ${inputLength}`
          : ` In ${provinceName} the ${fieldName} is typically ${inputLength}`
    }

    content = (
      <>
        <h3>Why we ask</h3>
        <p>
          We ask for the {fieldName} rather than your CRA Business Number because it is easier to verify businesses this
          way. You can usually find your {fieldName} through your provincial Companies Office website.
          {description}
        </p>
        {notRegisteredBusinessContent}
      </>
    )
  }

  return content
}

const BusinessIncorporationNumberRightPanelComponent = ({ activeClient, onNext }: RightPaneComponentType) => {
  const notRegisteredBusinessContent =
    activeClient?.businessType === 'Proprietorship' ? (
      <Button color="link" onClick={(e) => onNext({ e: e })} className="p-0">
        <u>I’m not a registered business</u>
      </Button>
    ) : null
  if (activeClient?.countryCode === 'US') {
    return (
      <>
        <h3>Why we ask</h3>
        <p>
          The nine-digit employer identification number (EIN) is assigned by the IRS. Rotessa can use this number to
          verify your business as an alternative to your State registry number.
        </p>
        {notRegisteredBusinessContent}
      </>
    )
  } else {
    return (
      <>
        <h3>What is this</h3>
        <p>
          Your CRA Business Number (BN) is a 9 digit number assigned by the Canada Revenue Agency to identify a
          business, incorporated company or society. The BN-9 is often just the first 9 digits of your 15 digit account
          number with the CRA.
        </p>
        {notRegisteredBusinessContent}
      </>
    )
  }
}

export const BusinessNumbersComponent = ({
  onNext,
  validate,
  handleFieldChange,
  activeClient,
  setCurrentPage,
  formRef,
}: OnboardingPageProps) => {
  const { t } = useTranslation()
  const [currentBusinessNumberStep, setCurrentBusinessNumberStep] = useState('businessRegistryId')
  const [didGoBackStep, setDidGoBackStep] = useState(false)
  const [stashedBusinessNumbers, setStashedBusinessNumbers] = useState<{
    businessRegistryId?: number
    businessIncorporationNumber?: string
  }>({})
  const [showIDontHaveRegistryId, setShowIDontHaveRegistryId] = useState(false)

  const handleNext = (event, values) => {
    switch (currentBusinessNumberStep) {
      case 'businessRegistryId':
        if (didGoBackStep) {
          onNext({ e: event, clientValues: { ...stashedBusinessNumbers, ...values } })
        } else {
          setStashedBusinessNumbers((prev) => ({
            ...prev,
            businessRegistryId: values.businessRegistryId,
          }))
          setCurrentBusinessNumberStep('businessIncorporationNumber')
        }
        break
      case 'businessIncorporationNumber':
        onNext({ e: event, clientValues: { ...stashedBusinessNumbers, ...values } })
        break
    }
  }

  const handleEnterRegistryIdInstead = () => {
    setCurrentBusinessNumberStep('businessRegistryId')
    setDidGoBackStep(true)
  }

  const handleIDontHaveRegistryId = () => {
    setCurrentBusinessNumberStep('businessIncorporationNumber')
    setShowIDontHaveRegistryId(false)
  }

  // Update right pane
  useEffect(() => {
    switch (currentBusinessNumberStep) {
      case 'businessRegistryId':
        setCurrentPage((prev) => ({ ...prev!, rightPane: BusinessRegistryIdRightPanelComponent }))
        break
      case 'businessIncorporationNumber':
        setCurrentPage((prev) => ({ ...prev!, rightPane: BusinessIncorporationNumberRightPanelComponent }))
        break
    }
  }, [currentBusinessNumberStep])

  let currentBusinessNumberContent = <Loading />
  const provinceCode = activeClient?.addressAttributes?.provinceCode || ''
  let registryLabel
  if (activeClient?.countryCode === 'US') {
    registryLabel = registryIdStatesCopy[provinceCode]?.fieldName || 'Business Registry ID Number'
  } else {
    registryLabel = registryIdProvincesCopy[provinceCode]?.fieldName || 'Business registry id'
  }
  let businessNumberLabel = activeClient?.countryCode === 'US' ? 'EIN Number' : 'CRA Business Number'

  switch (currentBusinessNumberStep) {
    case 'businessRegistryId':
      currentBusinessNumberContent = (
        <>
          <Modal isOpen={showIDontHaveRegistryId}>
            <ModalHeader>{registryLabel}</ModalHeader>
            <ModalBody>
              Your {registryLabel} is the simplest way for Rotessa to verify your business. If you aren't able to find
              your {registryLabel}, please enter your {businessNumberLabel} instead.
            </ModalBody>
            <ModalFooter>
              <Button onClick={handleIDontHaveRegistryId}>Enter my {businessNumberLabel}</Button>
              <Button color="primary" onClick={() => setShowIDontHaveRegistryId(false)}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <h3>{registryLabel}</h3>
          <AvField
            key="businessRegistryId"
            id="businessRegistryId"
            name="businessRegistryId"
            label={registryLabel}
            type="text"
            validate={{
              required: {
                value: true,
                errorMessage: t('required_field'),
              },
              async: validate,
            }}
            onChange={handleFieldChange}
            groupAttrs={{ className: 'mb-0' }}
          />
          <Button color="link" onClick={() => setShowIDontHaveRegistryId(true)} className="p-0 m-0">
            <small>
              <u>I can't find my {registryLabel}</u>
            </small>
          </Button>
        </>
      )
      break
    case 'businessIncorporationNumber':
      currentBusinessNumberContent = (
        <>
          <h3>{businessNumberLabel}</h3>
          <AvField
            key="businessIncorporationNumber"
            id="businessIncorporationNumber"
            name="businessIncorporationNumber"
            label={businessNumberLabel}
            type="text"
            validate={{
              required: {
                value: !stashedBusinessNumbers.businessRegistryId,
                errorMessage: t('required_field'),
              },
              async: validate,
            }}
            onChange={handleFieldChange}
            groupAttrs={{ className: 'mb-0' }}
          />
          {!stashedBusinessNumbers.businessRegistryId && (
            <Button color="link" onClick={handleEnterRegistryIdInstead} className="p-0 m-0">
              <small>
                <u>Enter my {registryLabel} instead</u>
              </small>
            </Button>
          )}
        </>
      )
  }
  return (
    <>
      <AvForm model={activeClient} onValidSubmit={handleNext} ref={formRef}>
        {currentBusinessNumberContent}
        <NextButton />
      </AvForm>
    </>
  )
}
