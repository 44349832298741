import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getActiveClient, getActiveUser } from 'app/selectors'
import { useLocation } from 'react-router'
import useScript from 'hooks/useScript'

export const UserDotComWidget = () => {
  const location = useLocation()
  const activeClient = useSelector(getActiveClient)
  const activeUser = useSelector(getActiveUser)
  var widgetState = 'simple'
  const chatNotShownPaths = [
    '/authorize/',
    '/donation/',
    '/link_authorizations',
    '/email_authorizations',
    '/customer_authorizations',
    '/microdeposit_verifications',
  ]
  useScript(process.env.REACT_APP_USER_COM_URL)

  useEffect(() => {
    const myWindow: any = window
    if (chatNotShownPaths.some((v) => location.pathname.includes(v))) {
      widgetState = 'hidden'
    } else {
      widgetState = 'simple'
    }
    myWindow.civchat = {
      first_name: activeUser?.firstName,
      user_id: activeUser?.publicId,
      email: activeUser?.email,
      widget: { state: widgetState },
      apiKey: process.env.REACT_APP_USER_COM_API_KEY,
    }
  }, [activeUser, activeClient, location])

  return null
}
