import React, { useState } from 'react'
import { Card, CardBody, Fade } from 'reactstrap'

import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from 'redux-query-react'
import { getActiveClient } from 'app/selectors'
import { ExternalTertiaryLink } from 'components/ExternalLink'
import { AvForm } from 'availity-reactstrap-validation'
import { FileUploader } from 'components/FileUploader'
import { FormServerErrors } from 'components/FormServerErrors'
import { transactionCsvPreview, TransactionCsvPreview200ResponseFromJSON } from 'api'
import { updateEntities } from 'redux-query'
import { useHistory } from 'react-router'
import { isPermitted } from 'utilities'
import { TitleBar } from 'features/customers/customerFlow/titleBar'
import { CenterContentNarrow } from 'components/CenterContent'
import { useSetBreadcrumbTitle } from 'hooks/useSetBreadcrumbTitle'

const csvTransactionTemplateURL = () => {
  return (
    <ExternalTertiaryLink to="/csv_templates/Rotessa_Transaction_Import_Template.csv" download>
      <i className="mdi mdi-download" /> Download transaction import template
    </ExternalTertiaryLink>
  )
}

export const TransactionImportPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const activeClient = useSelector(getActiveClient)
  const [errors, setErrors] = useState([] as string[])

  useSetBreadcrumbTitle('csv', 'CSV')

  const changeTransactionFileHandler = (file, accepted) => {
    if (accepted) {
      handleValidTransactionSubmit(file)
    } else {
      setErrors(['File not accepted. Please check that the file is a CSV.'])
    }
  }

  const handleValidTransactionSubmit = (file) => {
    doTransactionMutation(file)?.then((response) => {
      if (response?.body?.count > 0) {
        dispatch(
          updateEntities({
            previewCsvTransactions: (_) => TransactionCsvPreview200ResponseFromJSON(response?.body).records,
            previewCsvTransactionsCount: (_) => response?.body?.count,
          }),
        )
        history.push('/client/customers/imports/preview-transactions')
      } else {
        setErrors(response?.body?.errors || [])
      }
    })
  }

  const [queryState, doTransactionMutation] = useMutation((data: any) => {
    return transactionCsvPreview({ clientId: activeClient.id, file: data })
  })

  return (
    <Fade>
      <TitleBar
        title="Import transactions via CSV"
        subtitle="Import your customers' existing transactions from your database using Rotessa's CSV import. 
          First, download the import template and format your data according to our specific headings to import the data correctly."
      />
      {isPermitted('transaction_schedules.csv_imports', activeClient) && (
        <>
          <CenterContentNarrow>
            <Card>
              <CardBody>
                <p className="text-center">{csvTransactionTemplateURL()}</p>
                <AvForm onValidSubmit={handleValidTransactionSubmit}>
                  <FileUploader
                    onFileUpload={(file, accepted) => {
                      changeTransactionFileHandler(file, accepted)
                    }}
                    accept=".csv"
                    showPreview={true}
                  />
                  <div className="mt-2">
                    <FormServerErrors errors={errors} />
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </CenterContentNarrow>
        </>
      )}
    </Fade>
  )
}
