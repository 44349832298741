import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FormServerErrors, SmartFormServerErrors } from 'components/FormServerErrors'
import { Errors } from 'hooks/useFormServerErrors'
import { AvForm } from 'availity-reactstrap-validation'
import { BankAccountFields } from 'components/BankAccountFields'
import { isPermitted } from 'utilities'
import { FileUploader, getFileErrorMessages } from 'components/FileUploader'

import { Button, Card, CardBody, Row, Col, Fade, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap'

import { getActiveClient, getEntities } from 'app/selectors'
import { CenterContentNarrow } from 'components/CenterContent'
import { Customer, addCustomer, updateCustomerAuthorization } from 'api'
import { useMutation } from 'redux-query-react'
import { useClearEntitiesOnUnmount } from 'hooks/useClearOnUnmount'
import { TitleBar } from './titleBar'
import { AvGroup, AvFeedback, AvInput } from 'availity-reactstrap-validation'
import { LoadingMask } from 'components/LoadingMask'
import { MAX_FILE_SIZE } from 'app/constants'
export interface CustomerBankInfoProps {
  clientId?: number
}

export const CustomerBankInfo = (props: CustomerBankInfoProps) => {
  const history = useHistory()
  const activeClient = useSelector(getActiveClient) || {}
  const [isPending, setIsPending] = useState(false)
  const [serverErrors, setServerErrors] = useState<Errors>()
  const [uploadErrors, setUploadErrors] = useState([] as string[])
  const partialCustomer = useSelector((state) => getEntities(state).partialCustomer)
  const [authFormFile, setAuthFormFile] = useState<File>()
  const formRef = useRef<AvForm>()

  useClearEntitiesOnUnmount(['partialCustomer'])

  if (!partialCustomer) {
    history.go(-2)
  }

  const [saveCustomerState, doSaveCustomer] = useMutation((customer: Customer) => {
    return addCustomer({
      customer: customer,
    })
  })

  const [uploadFileState, doUploadFile] = useMutation((id: number, authForm?: File) => {
    return updateCustomerAuthorization({
      id: id,
      authForm: authForm,
    })
  })

  const handleChangeAuthFile = (file, accepted) => {
    if (accepted) {
      setAuthFormFile(file)
    } else {
      setUploadErrors([getFileErrorMessages(file, MAX_FILE_SIZE)])
    }
  }

  const onSubmit = (values) => {
    setIsPending(true)
    let customer: Customer = {
      clientId: activeClient?.id,
      ...partialCustomer,
      ...values,
    }
    doSaveCustomer(customer)?.then((response) => {
      if (response?.body?.status === 200 && response?.body?.id) {
        if (authFormFile) {
          doUploadFile(response.body.id, authFormFile)?.then((uploadResponse) => {
            if (uploadResponse?.body?.status === 200) {
              history.push(`/client/customers/new/optional-information/${response.body.id}`)
            } else {
              setIsPending(false)
              setUploadErrors(uploadResponse?.body?.messages?.file || [])
            }
          })
        } else {
          history.push(`/client/customers/new/optional-information/${response.body.id}`)
        }
      } else {
        setServerErrors(response?.body?.messages || [])
        setIsPending(false)
      }
    })
  }

  return (
    <Fade>
      <TitleBar title="Bank Information" />
      <div className="mb-2 mt-2">
        <CenterContentNarrow>
          <div className="page-title-box"></div>
          <SmartFormServerErrors errors={serverErrors} avFormRef={formRef} />
          <AvForm disabled={isPending} onValidSubmit={(_event, values) => onSubmit(values)} ref={formRef}>
            <Card>
              <CardBody>
                {isPending && <LoadingMask />}
                <BankAccountFields
                  fieldNamePrefix="bankAccount"
                  serverErrors={serverErrors}
                  countryCode={activeClient.countryCode}
                  allowMasked={!isPermitted('customers.view_unmasked_bank_numbers', activeClient)}
                  twoRows
                  required={!!isPermitted('customers.edit_bank_info', activeClient)}
                  disabled={!isPermitted('customers.edit_bank_info', activeClient)}
                />
                {activeClient?.countryCode === 'US' && (
                  <Row className="mb-3">
                    <Col>
                      <AvGroup>
                        <Label for="authorizationAmount">Authorization Limit</Label>
                        <InputGroup>
                          &nbsp;
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>$</InputGroupText>
                          </InputGroupAddon>
                          <AvInput
                            name="authorizationAmount"
                            id="authorizationAmount"
                            type="number"
                            validate={{
                              min: {
                                value: 1,
                                errorMessage: 'Must be 1 or greater',
                              },
                              required: {
                                errorMessage: 'This field is required',
                              },
                            }}
                            required
                            placeholder="0.00"
                          />
                          <AvFeedback>Must be 1 or greater</AvFeedback>
                        </InputGroup>
                      </AvGroup>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col>
                    <p>Authorization File Upload</p>
                    <FileUploader
                      name="file"
                      onFileUpload={(file, accepted) => {
                        handleChangeAuthFile(file, accepted)
                      }}
                      accept=".jpeg, .jpg, .pdf, .png"
                      showPreview={true}
                      maxFiles={1}
                      disabled={isPending}
                      maxSize={MAX_FILE_SIZE}
                    />
                    <div className="mt-2">
                      <FormServerErrors errors={uploadErrors} />
                    </div>
                  </Col>
                </Row>
                <div className="text-right">
                  <div className="inline-container">
                    <Button disabled={isPending} color="primary" className="ml-1 mb-2">
                      Next
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </AvForm>
        </CenterContentNarrow>
      </div>
    </Fade>
  )
}
