import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Row, Col, Badge, Card, CardBody, CustomInput } from 'reactstrap'
import { getActiveClient, getCustomer, getCustomerActions } from 'app/selectors'
import { ActionPromiseValue } from 'redux-query'
import { isPermitted } from 'utilities'
import { InfoPopover } from 'components/InfoPopover'
import { ExternalLink } from 'components/ExternalLink'

export interface CustomerNotificationSettingsProps {
  doAction: (action: string, onResponse?: (response: ActionPromiseValue<unknown>) => void) => void
}

export const CustomerNotificationSettings = ({ doAction }: CustomerNotificationSettingsProps) => {
  const customer = useSelector(getCustomer) || {}
  const activeClient = useSelector(getActiveClient) || {}
  const customerActions = useSelector(getCustomerActions) || {}

  return (
    <Card>
      <CardBody>
        <Row className="d-flex align-items-center">
          <Col>
            <h4 className="header-title no-wrap mb-3">Notification Settings</h4>
          </Col>
          <Col>
            {isPermitted('customers.update_customer_notifications', activeClient) && (
              <Link to="/client/settings/notification_settings" className="float-right mb-3">
                <span>Edit</span>
              </Link>
            )}
          </Col>
        </Row>
        <table>
          <tbody>
            <tr style={{ verticalAlign: 'top' }}>
              <td>
                <CustomInput
                  type="switch"
                  id="paymentReminder"
                  name="paymentReminder"
                  label={
                    <span className="font-weight-normal text-secondary">
                      Payment Reminder Notifications
                      <InfoPopover
                        popoverText={
                          <span>
                            Send email reminders to this customer for upcoming payments.{' '}
                            <ExternalLink to="https://support.rotessa.com/payment-reminders?utm_source=appfeaturetooltip">
                              Learn more.
                            </ExternalLink>
                          </span>
                        }
                      />
                    </span>
                  }
                  className="mt-2"
                  checked={customer?.notificationSettings?.paymentReminder?.enabled}
                  disabled={
                    !isPermitted('customers.update_customer_notifications', activeClient) ||
                    customer?.notificationSettings?.paymentReminder?.unsubscribed
                  }
                  onChange={(event) => {
                    const action = event.target.checked
                      ? customerActions.enablePaymentReminderNotifications
                      : customerActions.disablePaymentReminderNotifications
                    doAction(action)
                  }}
                >
                  {customer?.notificationSettings?.paymentReminder?.unsubscribed && (
                    <Badge pill color="secondary" className="ml-1">
                      Unsubscribed
                    </Badge>
                  )}
                </CustomInput>
              </td>
            </tr>
            <tr style={{ verticalAlign: 'top' }}>
              <td>
                <CustomInput
                  type="switch"
                  id="paymentReceipt"
                  name="paymentReceipt"
                  label={
                    <span className="font-weight-normal text-secondary">
                      Payment Receipt Notifications
                      <InfoPopover
                        popoverText={
                          <span>
                            Send email receipts to this customer for their payments.{' '}
                            <ExternalLink to="https://support.rotessa.com/payment-receipts?utm_source=appfeaturetooltip">
                              Learn more.
                            </ExternalLink>
                          </span>
                        }
                      />
                    </span>
                  }
                  className="mt-0"
                  style={{ display: 'inline' }}
                  checked={customer?.notificationSettings?.paymentReceipt?.enabled}
                  disabled={
                    !isPermitted('customers.update_customer_notifications', activeClient) ||
                    customer?.notificationSettings?.paymentReceipt?.unsubscribed
                  }
                  onChange={(event) => {
                    const action = event.target.checked
                      ? customerActions.enablePaymentReceiptNotifications
                      : customerActions.disablePaymentReceiptNotifications
                    doAction(action)
                  }}
                >
                  {customer?.notificationSettings?.paymentReceipt?.unsubscribed && (
                    <Badge pill color="secondary" className="ml-1">
                      Unsubscribed
                    </Badge>
                  )}
                </CustomInput>
              </td>
            </tr>
          </tbody>
        </table>
      </CardBody>
    </Card>
  )
}
