// tslint:disable
/**
 * Rotessa API
 * Access to the Rotessa backend system.
 *
 * The version of the OpenAPI document: 3.0.0
 * Contact: dev@rotessa.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewUser
 */
export interface NewUser  {
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    passwordConfirmation?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    businessName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    websiteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    referredBy?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    promoCode?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    userDotComKey?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    mobilePhone?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    position?: string;
}

export function NewUserFromJSON(json: any): NewUser {
    return {
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'passwordConfirmation': !exists(json, 'password_confirmation') ? undefined : json['password_confirmation'],
        'businessName': !exists(json, 'business_name') ? undefined : json['business_name'],
        'countryCode': !exists(json, 'country_code') ? undefined : json['country_code'],
        'websiteUrl': !exists(json, 'website_url') ? undefined : json['website_url'],
        'referredBy': !exists(json, 'referred_by') ? undefined : json['referred_by'],
        'promoCode': !exists(json, 'promo_code') ? undefined : json['promo_code'],
        'userDotComKey': !exists(json, 'user_dot_com_key') ? undefined : json['user_dot_com_key'],
        'mobilePhone': !exists(json, 'mobile_phone') ? undefined : json['mobile_phone'],
        'position': !exists(json, 'position') ? undefined : json['position'],
    };
}

export function NewUserToJSON(value?: NewUser): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email': value.email,
        'password': value.password,
        'password_confirmation': value.passwordConfirmation,
        'business_name': value.businessName,
        'country_code': value.countryCode,
        'website_url': value.websiteUrl,
        'referred_by': value.referredBy,
        'promo_code': value.promoCode,
        'user_dot_com_key': value.userDotComKey,
        'mobile_phone': value.mobilePhone,
        'position': value.position,
    };
}


