import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRequest, useMutation } from 'redux-query-react'

import { useHistory } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Fade,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Badge,
} from 'reactstrap'
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'

import { Customer, searchCustomers, updateCustomer, updateCustomerAuthorization, getCustsomerPermissionForm } from 'api'
import { getActiveClient, getEntities } from 'app/selectors'
import { Loading } from 'components/Loading'
import { BankAccountFields } from 'components/BankAccountFields'

import { useSetBreadcrumbTitle } from 'hooks/useSetBreadcrumbTitle'
import { FileUploader, getFileErrorMessages } from 'components/FileUploader'
import { CenterContent } from 'components/CenterContent'
import { SmartFormServerErrors, FormServerErrors } from 'components/FormServerErrors'
import { Errors, useFormServerErrors } from 'hooks/useFormServerErrors'
import { isPermitted } from 'utilities'
import { useIsMobile } from 'hooks/useIsMobile'
import { MAX_FILE_SIZE } from 'app/constants'
import { OpenFileLink } from 'components/ExportButton'

export interface CustomerBankAndAuthFormProps {
  clientId?: number
  customerId: number
}

export const CustomerBankAndAuthForm = ({ customerId }: CustomerBankAndAuthFormProps) => {
  const history = useHistory()
  const [isPending, setIsPending] = useState(false)
  const [serverErrors, setServerErrors] = useState<Errors>()
  const { handleFieldChange, validate } = useFormServerErrors(serverErrors, setServerErrors)
  const [uploadErrors, setUploadErrors] = useState([] as string[])
  const [authFormFile, setAuthFormFile] = useState<File>()
  const [formLoaded, setFormLoaded] = useState(false)
  const activeClient = useSelector(getActiveClient) || {}
  const isMobile = useIsMobile()
  const formRef = useRef<AvForm>()

  const customer: Customer = useSelector((state) => getEntities(state).customer) || {}
  useSetBreadcrumbTitle(customer?.id?.toString(), customer?.name || '')
  const [authorizationLimit, setAuthorizationLimit] = useState('no authorization limit')
  const [requestState, doRequest] = useRequest(
    !activeClient?.id
      ? null
      : searchCustomers(
          {
            clientId: activeClient?.id || 0,
            customerId: customerId,
          },
          {
            force: true,
            transform: (response) => {
              return { customer: response.records?.[0] }
            },
            update: {
              customer: (_, newValue) => newValue,
            },
          },
        ),
  )

  const [saveCustomerState, doSaveCustomer] = useMutation((customer: Customer) => {
    if (authorizationLimit !== 'true') {
      customer.authorizationAmount = 0
    }
    if (!isPermitted('customers.edit_bank_info', activeClient)) {
      customer.bankAccount = undefined
    }
    return updateCustomer({
      id: customerId,
      customer: customer,
    })
  })

  const [uploadFileState, doUploadFile] = useMutation((id: number, authForm?: File) => {
    return updateCustomerAuthorization({
      id: id,
      authForm: authForm,
    })
  })

  function handleValidSubmit(event, customer) {
    setIsPending(true)
    customer.clientId = activeClient.id
    doSaveCustomer(customer)?.then((response) => {
      setServerErrors(response?.body?.messages || [])
      if (response?.body?.status === 200 && response?.body?.id) {
        // then upload auth file if present
        if (authFormFile) {
          doUploadFile(response.body.id, authFormFile)?.then((uploadResponse) => {
            setIsPending(false)
            setUploadErrors(uploadResponse?.body?.messages?.file || [])
            if (uploadResponse?.body?.status === 200) {
              history.push(`/client/customers/${response.body.id}`)
            }
          })
        } else {
          history.push(`/client/customers/${response.body.id}`)
        }
      } else {
        setIsPending(false)
      }
    })
  }

  const handleChangeAuthFile = (file, accepted) => {
    if (accepted) {
      setAuthFormFile(file)
    } else {
      setUploadErrors([getFileErrorMessages(file, MAX_FILE_SIZE)])
    }
  }

  if (requestState.isPending) {
    return <Loading />
  }

  if (requestState.isFinished && !formLoaded) {
    setAuthorizationLimit(
      !customer.authorizationAmount || customer.authorizationAmount === 0 ? 'no authorization limit' : 'true',
    )
    setFormLoaded(true)
  }

  const title = 'Edit Bank & Authorization Details'

  return (
    <Fade>
      <div className="mb-2">
        <CenterContent>
          <div className="page-title-box">
            <h4 className="page-title">{title}</h4>
          </div>
          <SmartFormServerErrors errors={serverErrors} avFormRef={formRef} />
          <AvForm disabled={isPending} onValidSubmit={handleValidSubmit} model={customer} ref={formRef}>
            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col xs="auto" className="text-nowrap header-title mb-0">
                    Bank Account
                  </Col>
                  <Col>
                    <Badge pill color="info-lighten" style={{ height: 'fit-content' }}>
                      Required to process transactions
                    </Badge>
                  </Col>
                </Row>
                <BankAccountFields
                  fieldNamePrefix="bankAccount"
                  serverErrors={serverErrors}
                  countryCode={activeClient.countryCode}
                  allowMasked={!isPermitted('customers.view_unmasked_bank_numbers', activeClient)}
                  disabled={!isPermitted('customers.edit_bank_info', activeClient)}
                />
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row>
                  <Col lg={6}>
                    <AvField
                      validate={{ async: validate }}
                      onChange={handleFieldChange}
                      name="authorizationType"
                      label={
                        activeClient.countryCode === 'CA' ? (
                          <Row style={{ width: isMobile ? '100%' : '200%' }}>
                            <Col xs="auto" className="text-nowrap">
                              Authorization Type
                            </Col>
                            <Col>
                              <Badge pill color="info-lighten" style={{ height: 'fit-content' }}>
                                Required to process transactions
                              </Badge>
                            </Col>
                          </Row>
                        ) : (
                          <>Authorization Type</>
                        )
                      }
                      type="select"
                    >
                      <option value=""></option>
                      <option value="Online">Online</option>
                      <option value="In Person">In Person</option>
                      {customer && customer.authorizationType && customer.authorizationType === 'Phone' && (
                        <option value="Phone">Phone</option>
                      )}
                    </AvField>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                {isPermitted('customers.view_permission_form', activeClient) && (
                  <>
                    <Row>
                      <Col md={9}>
                        <h4 className="header-title mb-3">Authorization Form</h4>
                      </Col>
                    </Row>
                    <Row className="mb-2">
                      <Col>
                        <Label>
                          Authorization Form
                          {customer?.permissionForm?.url && (
                            <span className="ml-2">
                              (Currently Uploaded:{' '}
                              <OpenFileLink
                                exportQueryConfig={getCustsomerPermissionForm(
                                  {
                                    id: customer.id || 0,
                                  },
                                  {
                                    force: true,
                                  },
                                )}
                              >
                                authorization_form
                              </OpenFileLink>
                              )
                            </span>
                          )}
                        </Label>
                        <FileUploader
                          name="file"
                          onFileUpload={(file, accepted) => {
                            handleChangeAuthFile(file, accepted)
                          }}
                          accept=".jpeg, .jpg, .pdf, .png"
                          showPreview={true}
                          maxFiles={1}
                          disabled={isPending}
                          maxSize={MAX_FILE_SIZE}
                        />
                      </Col>
                    </Row>
                    {uploadErrors && (
                      <Row>
                        <Col>
                          <FormServerErrors errors={uploadErrors || []} />
                        </Col>
                      </Row>
                    )}
                  </>
                )}
                <Row>
                  <Col>
                    <label>Authorization Limit</label>
                    <p>
                      This is the maximum amount the customer agrees to let you debit from their account. Authorization
                      from your customer is required before increasing this limit. If you have an existing open
                      authorization, choose "No authorization limit".
                    </p>
                    <div>
                      <input
                        type="radio"
                        name="authorizationLimit"
                        id="authorizationLimit_radio_none"
                        value="no authorization limit"
                        checked={authorizationLimit === 'no authorization limit'}
                        onChange={(e) =>
                          setAuthorizationLimit(authorizationLimit === 'true' ? 'no authorization limit' : 'true')
                        }
                      />
                      <label htmlFor="authorizationLimit_radio_none">&nbsp;No authorization limit</label>
                      <br />
                      <input
                        type="radio"
                        name="authorizationLimit"
                        id="authorizationLimit_radio_amount"
                        value="true"
                        checked={authorizationLimit === 'true'}
                        onChange={(e) => {
                          setAuthorizationLimit(authorizationLimit === 'true' ? 'no authorization limit' : 'true')
                        }}
                      />
                      <label htmlFor="authorizationLimit_radio_amount">
                        <AvGroup>
                          <InputGroup>
                            &nbsp;
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>$</InputGroupText>
                            </InputGroupAddon>
                            <AvInput
                              name="authorizationAmount"
                              placeholder="0.00"
                              value={customer.authorizationAmount}
                              validate={{
                                required: {
                                  value: authorizationLimit === 'true',
                                  errorMessage: 'This field is required',
                                },
                                min: {
                                  value: authorizationLimit === 'true' ? 1 : 0,
                                  errorMessage: 'Must be 1 or greater',
                                },
                              }}
                              onChange={handleFieldChange}
                              disabled={authorizationLimit === 'no authorization limit'}
                              type="number"
                            />
                            <AvFeedback>Must be 1 or greater</AvFeedback>
                          </InputGroup>
                        </AvGroup>
                      </label>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <div className="text-right">
              <div className="inline-container">
                <Button
                  disabled={isPending}
                  onClick={() => history.goBack()}
                  className="btn-rotessa-secondary min-width ml-1 mb-2"
                >
                  Cancel
                </Button>
                <Button disabled={isPending} color="primary" className="ml-1 mb-2">
                  Submit
                </Button>
              </div>
            </div>
          </AvForm>
        </CenterContent>
      </div>
    </Fade>
  )
}
